import React from "react";
import { Header } from "../header";
import { Footer } from "../home/footer";
import { Hireteam } from "./hireteam";
import { TestingDevelopmentData } from "./customdev/accordianData";
import { Contacttag } from "./customdev";
import Accordion from "./accordian";
import TechnologyStackGrid from "./techstack/techStack";
import { TestingTechnologyStackData } from "./technicalstacks";

const services = [
  {
    id: 1,
    imgSrc: "images/service-icon-4.webp",
    title: "Functional Testing",
    description: "Validating the software system to conform to each functional requirement of the application meets the expected output. Our experienced QA testing team implements functional testing to majorly focus on user interface, database, security, API's, client or server application and similar functionalities of the application."
  },
  {
    id: 2,
    imgSrc: "images/service-icon-4.webp",
    title: "Performance Testing",
    description: "Increasing load time, inefficient responsiveness and instability can be overcome by performance testing. Our profound experience in performance testing by executing quality checks and software behaviour analysis under load and stress guarantees flawless, reliable and smarter applications."
  },
  {
    id: 3,
    imgSrc: "images/service-icon-4.webp",
    title: "Security Testing",
    description: "Uncover potential vulnerabilities of your running application by enabling security testing with every release. Our QA testing services are performed by a talented pool of test engineers who execute risk assessment and penetration testing to shield applications from hackers or malicious threats."
  },
  {
    id: 4,
    imgSrc: "images/service-icon-4.webp",
    title: "Usability Testing",
    description: "Monitor application behaviour when multiple functions of the application operate concurrently. Our software testing services entail an in-depth analysis of user behaviour patterns that identify potential usability issues in the initial phase and create a seamless product."
  },
  {
    id: 5,
    imgSrc: "images/service-icon-4.webp",
    title: "Compatibility Testing",
    description: "Explore the possibilities of compatibility testing with RiseProSoft Solutions's software testing services where an application is tested meticulously across different browsers, databases, hardware, operating systems, server infrastructures, resolution displays and devices."
  },
  {
    id: 6,
    imgSrc: "images/service-icon-4.webp",
    title: "Mobile App Testing",
    description: "Scalability, security and multi-platform support are the cornerstones of a mobile app. RiseProSoft Solutions provides cross platform, functional/non-functional testing with manual testing as well as automated testing approach for diverse mobile applications."
  }
];





export const Testing = () => {
  return (
    <React.Fragment>
      <Header />
      <section className="testing-section">
        <div className="container">
          <div className="banner-content-wrapper">
            <div className="container">
              <div className="banner-content text-center">
                <h1 className="text-white">
                Software Testing Services
                </h1>
                <p className="text-white">
                Experience Next-Gen software testing and Quality Assurance services with RiseProSoft Solutions. We guarantee significant boost in deploying codes, improved Software development life cycle and cost-effectiveness at an unparalleled speed and quality.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{paddingBottom:'0'}}>
        <div className="container">
          <div className="title-block text-center mb-4">
            <h2>Software Testing & QA Services</h2>
            <p>
            We offer businesses contractual engagement methods with an ability to flex software outsourcing as per business needs and project scope ensuring higher efficiency and top-notch resources. Our wide range of service offerings spans across different domains to provide ongoing project support with high-end software development solutions.
            </p>
          </div>
          <div className="work-grid-wrapper">
            <div className="row">
              {services.map((service) => (
                <div className="col-md-4 mb-4" key={service.id}>
                  <div className="dev-card">
                    <div className="icon">
                      <img src={service.imgSrc} alt={service.altText} />
                    </div>
                    <div className="card-body">
                      <h4 className="card-title">
                        <a href={service.link} title={service.title}>
                          {service.title}
                        </a>
                      </h4>
                      <p className="card-text">{service.description}</p>
                      <p className="text-end text-sm more-content">More <i className="fa fa-arrow-right" style={{color:'#1329e9'}}></i></p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="technology-stack-section">
  <div className="container">
    <h2>Technology Stack</h2>
   <TechnologyStackGrid data={TestingTechnologyStackData} />
  </div>
</section>
<Hireteam title={'Software Testing and QA Developers'} />
<Contacttag />
<section className="risepro-faq-section pb-0">
  <div className="container">
    <h2 className="mb-5 text-center">Software Testing and QA Services FAQs</h2>
    <Accordion items={TestingDevelopmentData} />
  </div>
</section>
      <Footer/>
    </React.Fragment>
  );
};
