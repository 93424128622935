import React from "react";
import { Header } from "../header";
import { Footer } from "../home/footer";
import { Contacttag } from "./customdev";
import Accordion from "./accordian";
import { ProductDevelopmentData } from "./customdev/accordianData";
import { Hireteam } from "./hireteam";
import TechnologyStackGrid from "./techstack/techStack";
import { ProductTechnologyStackData } from "./technicalstacks";

const services = [
  {
    id: 1,
    imgSrc: "images/service-icon-4.webp",
    title: "Prototyping & MVP Development",
    description: "Discover, identify, and outline product specific demands by creating prototype based on the latest market trends and customer feedbacks. Utilize our prototyping approach and MVP development service to test your software product under specific market conditions and minimize risk."
  },
  {
    id: 2,
    imgSrc: "images/service-icon-4.webp",
    title: "Product Architecture Design Service",
    description: "Evolve with ground-breaking trends by drilling deep into the market and intellectually translating the needs into product features. Our skilled software product developers delve deep into each product architectural layer and derive insights to add competitive value to your business."
  },
  {
    id: 3,
    imgSrc: "images/service-icon-4.webp",
    title: "UI/UX Design Service",
    description: "Well defined UI/UX strategy to trace business workflows by creating information architecture, wireframing, and UX designing to engross more customers. Our UI/UX designers adapt design thinking approach to offer innovative product designs that are compelling, intuitive, and user-friendly in accordance with the current market."
  },
  {
    id: 4,
    imgSrc: "images/service-icon-4.webp",
    title: "Product Development",
    description: "Aligning to specific business needs, we offer end-to-end software product development services using multiple developments and operational methodologies like SCRUM, Agile, DevOps, Waterfall and Prototyping. Our adroit technical leads use razor-edge technology solutions, offer product innovation, accelerate time to market, and improve profitability."
  },
  {
    id: 5,
    imgSrc: "images/service-icon-4.webp",
    title: "Product Testing",
    description: "Adhering to standards of Quality Assurance, we abide by strict quality analysis from Validation, Functional, Performance, Smoke and UAT testing to assure the developed product is stable, scalable and secure. Our expert QA team ensures our developed software products are easily customizable, resilient, and integrable at all levels."
  },
  {
    id: 6,
    imgSrc: "images/service-icon-4.webp",
    title: "Maintenance & Support Services",
    description: "Our software maintenance and support teams upgrade and update the product with the latest technology to optimize processes and improve overall efficiency. Our experienced software developers ensure timely support and hassle-free services including but not limited to resolving issues, integration, migration, and enhancement to the clients."
  }
];




export const Productdev = () => {
  return (
    <React.Fragment>
      <Header />
      <section className="product-section">
        <div className="container">
          <div className="banner-content-wrapper">
            <div className="container">
              <div className="banner-content text-center">
                <h1 className="text-white">
                Software Product Development Company
                </h1>
                <p className="text-white">
                Give your business vision a life, turn it into a reality. RiseProSoft Solutions offers an intuitive and design-led software product development services to accelerate software product innovation, deliver a superior customer experience and give competitive edge to your software products.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{paddingBottom:'0'}}>
        <div className="container">
          <div className="title-block text-center mb-4">
            <h2>Software Product Development Services</h2>
            <p>
            Capitalize on our diversified capabilities of end-to-end product engineering services
            </p>
          </div>
          <div className="work-grid-wrapper">
            <div className="row">
              {services.map((service) => (
                <div className="col-md-4 mb-4" key={service.id}>
                  <div className="dev-card">
                    <div className="icon">
                      <img src={service.imgSrc} alt={service.altText} />
                    </div>
                    <div className="card-body">
                      <h4 className="card-title">
                        <a href={service.link} title={service.title}>
                          {service.title}
                        </a>
                      </h4>
                      <p className="card-text">{service.description}</p>
                      <p className="text-end text-sm more-content">More <i className="fa fa-arrow-right" style={{color:'#1329e9'}}></i></p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="risepro-technology-stack-section">
  <div className="container">
    <h2 className="text-center mb-3">Technology Stack</h2>
   <TechnologyStackGrid data={ProductTechnologyStackData} />
  </div>
</section>
<Hireteam title={'Product Developers'} />

<Contacttag />
<section className="risepro-faq-section pb-0">
  <div className="container">
    <h2 className="mb-5 text-center">Product Development FAQs</h2>
    <Accordion items={ProductDevelopmentData} />
  </div>
</section>
      <Footer/>
    </React.Fragment>
  );
};
