import React from "react";
import { Header } from "../header";
import { Footer } from "../home/footer";
import { Contacttag } from "./customdev";
import Accordion from "./accordian";
import { WebDevelopmentData } from "./customdev/accordianData";
import { Hireteam } from "./hireteam";
// Array of service data
const services = [
  {
    id: 1,
    imgSrc: "images/service-icon-1.webp",
    title: "Front-end Web Development",
    description: "Utilize the reusable components and deliver component-driven architecture using advanced Frontend technologies like React.js, Angular, and Vue.js. RiseProSoft Solutions is a web application development company that combines amazing UI designs with required functionalities effortlessly."
  },
  {
    id: 2,
    imgSrc: "images/service-icon-1.webp",
    title: "Back-end Development",
    description: "Seamlessly navigate between multiple features of an application by accessing the talented pool of backend web developers who can make your application scalable. We offer AWS and Azure services to host the backend services on cloud, and SQL and NoSQL databases to store and fetch data reliably using Microservices."
  },
  {
    id: 3,
    imgSrc: "images/service-icon-1.webp",
    title: "SaaS Application Development",
    description: "Comprehensively delve deep into the client's need to understand the scope of web application and its expandability. Our team of web app developers are adept and backed up by technical soundness to offer secured and multi-tenant SaaS application development solutions benefiting business in the longer run."
  },
  {
    id: 4,
    imgSrc: "images/service-icon-1.webp",
    title: "Progressive Web Apps",
    description: "Aligning to the modern needs of app development, we create native web apps with enhanced technology features, faster application load times, highly interactive visual content and hassle-free navigation between distinct features within the application."
  },
  {
    id: 5,
    imgSrc: "images/service-icon-1.webp",
    title: "Ecommerce Web App Development Company",
    description: "Customize and build eCommerce Web Apps using Shopify, Magento and custom frameworks to amplify business growth and boost online web presence. Our adept web developers ensure growth and user engagement by developing agile, secure, & user-friendly eCommerce apps."
  },
  {
    id: 6,
    imgSrc: "images/service-icon-1.webp",
    title: "Custom Web Application Development Services",
    description: "We help you deliver superior web development services combined with expert resources, technical prowess to strengthen overall business strategy. Our experience in custom web app development assures compatibility, scalability and intuitive user experiences."
  }
];

export const Webdev = () => {
  return (
    <React.Fragment>
      <Header />
      <section className="RiseProSoft Solutions-inner-page-banner banner-with-css-overlay webdevelopment-section">
        <div className="banner-content-wrapper">
          <div className="container">
            <div className="banner-content text-center">
              <h1 className="text-white">Web Application Development</h1>
              <p className="text-white">
                Design, develop and deploy web applications to bring impactful changes within your web development projects. RiseProSoft Solutions's development approach assists clients right from analyzing disruptive web development challenges to create an intuitive UI/UX, fully functional, and powerful web based solution.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section style={{paddingBottom:'0'}}>
        <div className="container">
          <div className="title-block text-center mb-4">
            <h2>Web Application Development Services</h2>
            <p>With RiseProSoft Solutions, accelerate your pace of web application development to gain competitive advantage</p>
          </div>
          <div className="work-grid-wrapper">
            <div className="row">
              {services.map((service) => (
                <div className="col-md-4 mb-4" key={service.id}>
                  <div className="dev-card">
                    <div className="icon">
                      <img src={service.imgSrc} alt={service.title} />
                    </div>
                    <div className="card-body">
                      <h4 className="card-title">
                        {service.title}
                      </h4>
                      <p className="card-text">
                        {service.description}
                      </p>
                      <p className="text-end text-sm more-content">More <i className="fa fa-arrow-right" style={{color:'#1329e9'}}></i></p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="risepro-technology-stack-section">
  <div className="container">
    <h2 className="text-center mb-3">Technology Stack</h2>
    <div className="technology-stack-grid-new row align-items-start" >
      <div className="grid-item col-md-12 col-lg-6 col-xl-4">
        <div className="grid-inner">
          <h3>
            <span>
              <img
                data-src="images/tech_backend.svg"
                alt="frameworks"
                className="js-lazy-image js-lazy-image--handled"
                src="images/tech_backend.svg"
              />
            </span>
            Backend
          </h3>
          <div className="tech-stack-list-wrapper">
            <ul>
              <li>.NET</li>
              <li>Java</li>
              <li>Node</li>
              <li>PHP</li>
              <li>Ruby on Rails</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="grid-item col-md-12 col-lg-6 col-xl-4">
        <div className="grid-inner">
          <h3>
            <span>
              <img
                data-src="images/frontend-orange.svg"
                alt="Frontend"
                className="js-lazy-image js-lazy-image--handled"
                src="images/frontend-orange.svg"
              />
            </span>
            Frontend
          </h3>
          <div className="tech-stack-list-wrapper">
            <ul>
              <li>Angular</li>
              <li>React</li>
              <li>Vue</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="grid-item col-md-12 col-lg-6 col-xl-4">
        <div className="grid-inner">
          <h3>
            <span>
              <img
                data-src="images/database-red.svg"
                alt="Database"
                className="js-lazy-image js-lazy-image--handled"
                src="images/database-red.svg"
              />
            </span>
            Database
          </h3>
          <div className="tech-stack-list-wrapper">
            <ul>
              <li>SQL Server</li>
              <li>PostgreSQL</li>
              <li>MySQL</li>
              <li>MongoDB</li>
              <li>Azure SQL</li>
            </ul>
            <ul>
              <li>Amazon RDS</li>
              <li>Oracle</li>
              <li>Cassandra</li>
              <li>Elasticsearch</li>
              <li>Dynamo</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="grid-item col-md-12 col-lg-6 col-xl-4">
        <div className="grid-inner">
          <h3>
            <span>
              <img
                data-src="images/tech_mobility.svg"
                alt="FrontEnd"
                className="js-lazy-image js-lazy-image--handled"
                src="images/tech_mobility.svg"
              />
            </span>
            Mobility
          </h3>
          <div className="tech-stack-list-wrapper">
            <ul>
              <li>iOS</li>
              <li>Android</li>
              <li>React Native</li>
              <li>Flutter</li>
              <li>Ionic</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="grid-item col-md-12 col-lg-6 col-xl-4">
        <div className="grid-inner">
          <h3>
            <span>
              <img
                data-src="images/cloud-devops-pink.svg"
                alt="DevOps"
                className="js-lazy-image js-lazy-image--handled"
                src="images/cloud-devops-pink.svg"
              />
            </span>
            Cloud &amp; DevOps
          </h3>
          <div className="tech-stack-list-wrapper">
            <ul>
              <li>AWS</li>
              <li>Azure</li>
              <li>Google Cloud</li>
              <li>Docker</li>
              <li>Kubernetes</li>
            </ul>
            <ul>
              <li>Jenkins</li>
              <li>Terraform</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="grid-item col-md-12 col-lg-6 col-xl-4">
        <div className="grid-inner">
          <h3>
            <span>
              <img
                data-src="images/qa-testing-green.svg"
                alt="Testing"
                className="js-lazy-image js-lazy-image--handled"
                src="images/qa-testing-green.svg"
              />
            </span>
            Testing
          </h3>
          <div className="tech-stack-list-wrapper">
            <ul>
              <li>Selenium</li>
              <li>Katalon Studio</li>
              <li>Apache JMeter</li>
              <li>TestRail</li>
              <li>Postman</li>
            </ul>
            <ul>
              <li>BrowserStack</li>
              <li>OWASP ZAP</li>
              <li>JUnit, NUnit</li>
              <li>Jest</li>
              <li>Jasmine, Mocha, Chai</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="grid-item col-md-12 col-lg-6 col-xl-4">
        <div className="grid-inner">
          <h3>
            <span>
              <img
                data-src="images/other-tools-pink.svg"
                alt="database"
                className="js-lazy-image js-lazy-image--handled"
                src="images/other-tools-pink.svg"
              />
            </span>
            Others
          </h3>
          <div className="tech-stack-list-wrapper">
            <ul>
              <li>SharePoint</li>
              <li>BizTalk</li>
            </ul>
            <ul>
              <li>Power BI</li>
              <li>Apache Kafka</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<Hireteam title={'Web Application Developers'} />
<Contacttag />
<section className="risepro-faq-section pb-0">
  <div className="container">
    <h2 className="mb-5 text-center">Web Application Development FAQs</h2>
    <Accordion items={WebDevelopmentData} />
  </div>
</section>
      <Footer/>
    </React.Fragment>
  );
};
