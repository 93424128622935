import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Homesection } from "./components/homecomponent";
import "react-multi-carousel/lib/styles.css";
import { Customdev } from "./components/development/customdev";
import { Webdev } from "./components/development/webdev";
import { Appdev } from "./components/development/appdev";
import { Productdev } from "./components/development/productdev";
import { DedicatedTeam } from "./components/development/dedicatedteam";
import { Testing } from "./components/development/testing";
import ScrolltoTop from "./components/scrolltoTop";
import { About } from "./components/about";
import { Contactus } from "./components/contact";
import {Technologies} from "./components/home/technologies";

function App() {
  return (
    <React.Fragment>
      <Router>
      <ScrolltoTop />
        <Routes>
          <Route path="/" Component={Homesection} />
          <Route path="/about" Component={About} />
          <Route path="/custom-development" Component={Customdev} />
          <Route path="/web-development" Component={Webdev} />
          <Route path="/app-development" Component={Appdev} />
          <Route path="/product-development" Component={Productdev} />
          <Route path="/sap-services" Component={DedicatedTeam} />
          <Route path="/testing" Component={Testing} />
          <Route path="/contactus" Component={Contactus} />
          <Route path="/technologies" Component={Technologies}/>
        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default App;
