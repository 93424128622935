import React from "react";
import { Header } from "./header";
import { Footer } from "./home/footer";

export const About = () => {
  return (
    <React.Fragment>
      <Header />
      <section className="about-section">
        <div className="container">
          <div className="banner-content-wrapper">
            <div className="container">
              <div className="banner-content text-center">
                <h1 className="text-white">About Us</h1>
                <p className="text-white">
                  Creating technology solutions that enable businesses to
                  achieve success.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
      
          <div className="about-us-content-section">
            <div className="">
              <div className="about-us-content">
                <p>
                We are a Consummate Custom Software Development company delivering splendid business IT Solutions and related services to customers across the globe. Our development services are led by our dedicated and passionate team to provide best industry practices combined with technology expertise and business domain knowledge to drive digital transformation. Our proficiency in understanding business challenges and professional competence allows us to create a better experience for our customers.
                </p>
                <p>
                  We have emerged and marked our presence in different
                  continents by providing Bespoke software development services
                  to all major Industry Domains.
                </p>
              </div>
              <div className="about-us-wrapper">
                <div className="inner-col">
                  <p>
                    We have successfully served for more than 1800 success
                    stories ranging from Enterprise level to Start-ups, who have
                    grown alongside the success of the company.{" "}
                  </p>
                </div>
                <div className="inner-col">
                  <p>
                    At present we are a Robust Team having diverse skills with
                    more than 21+ years of Technology experience engaging with
                    customers at Deeper level to provide cutting edge solutions
                    and innovations.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </React.Fragment>
  );
};
