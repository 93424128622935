import React from "react"
import { Header } from "./header"
import { Homesec } from "./home/homesec"
// import { OrganiseSec } from "./home/organise"
// import {Consulting} from "./home/consulting"
// import { Differentiators } from "./home/difference"
import { Insights } from "./home/insights"
import { SapUser } from "./home/sapuser"
import { TrustSection } from "./home/trust"
// import { Additional } from "./home/additional"
import { Footer } from "./home/footer"
import { Development } from "./home/development"

export const Homesection=()=> {
    return(
        <React.Fragment>
            <Header/>
            <Homesec/>
            {/* <OrganiseSec/> */}
            {/* <Consulting/> */}
            {/* <Differentiators/> */}
            <Insights/>
            <SapUser/>
            <TrustSection/>
            <Development/>
            {/* <Additional/> */}
            <Footer/>
        </React.Fragment>
    )
}