import React, { useState } from 'react';

const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <ul className="faq-listing" style={{maxWidth:'992px',margin:'auto'}}>
      {items.map((item, index) => (
        <li className="faq-inner" key={index}>
          <div className="faq-title" onClick={() => onTitleClick(index)}>
            <h5 className='d-flex align-items-center gap-2 justify-content-between'>{item.title} <i className={`fa ${activeIndex !== index ? 'fa-angle-right' : 'fa-angle-down'}`}></i></h5>
          </div>
          
            <div className={`faq-detail ${
                activeIndex === index ? 'active' : ''
              }`}>
              <p dangerouslySetInnerHTML={{ __html: item.content }}></p>
            </div>
        
        </li>
      ))}
    </ul>
  );
};

export default Accordion;
