import React from "react";
import { Header } from "../header";
import { Footer } from "../home/footer";

const services = [
  {
    id: 1,
    imgSrc: "images/service-icon-1.webp",
    title: "SAP Service Offerings",
    description: "Risepro Soft Solutions is one of the handful of SAP solution providers with a wide spectrum of competencies under one umbrella, right from sizing and provisioning the hardware and network for your mission-critical SAP system (on-premise or on-cloud) to migration, implementation, rollouts, upgrade, disaster recovery, and AMS or shared support for your SAP ERP suite, SAP S/4 HANA or any other New Dimension Products from SAP."
  },
  {
    id: 2,
    imgSrc: "images/service-icon-4.webp",
    title: "SAP Application Management Support (AMS) Services",
    description: "Ensuring seamless operation of business processes mapped in SAP is critical for the smooth functioning of your organization. Our team of SAP consultants, boasting both core and niche skills, are dedicated to ensuring that these processes run without interruption, whether it be delivery truck logistics, finished goods inspection, or the generation of monthly pay-slips for employees. We offer a range of SAP Application Management Support options, including dedicated onsite support, remote shared services, and 24x7 and 16x8 support models. Our SAP Support for SAP Business Suite, including SAP ECC6.0 and SAP S/4 HANA, has been certified by SAP SE through rigorous bi-annual audits."
  },
  {
    id: 3,
    imgSrc: "images/service-icon-4.webp",
    title: "SAP Migration Services",
    description: "Risepro Soft Solutions CloudInfinit offers end-to-end managed services for your business-critical SAP application, ensuring a scalable, secure, and seamless migration to future-ready infrastructure. Our team of experts have successfully executed various SAP migrations, both homogenous and heterogeneous, with minimal downtime and zero business disruption. We have experience migrating to popular cloud platforms such as AWS and Azure, and have successfully executed Suite on Hana (SoH) and S4H migrations. Our experts work closely with our infrastructure team to ensure even the most complex SAP implementation can be migrated seamlessly and cost-effectively."
  },
  {
    id: 4,
    imgSrc: "images/service-icon-4.webp",
    title: "SAP BASIS Services",
    description: "SAP Basis is the nerve center of your SAP landscape. For the smooth functioning of your mission- critical SAP application 24x7, it is important to monitor and manage it correctly. Our dedicated SAP Basis experts provide dedicated AMS or Shared Services, in addition to the ongoing Production Support, to help you upgrade your SAP system, migrate your SAP landscape to cloud, host greenfield implementations on cloud, configure SAP DR (disaster recovery) or benefit from single-point monitoring services from the Solutions Manager or the change request tool."
  },
  {
    id: 5,
    imgSrc: "images/service-icon-4.webp",
    title: "SAP Upgrade Services",
    description: "Risepro Soft Solutions has extensive experience and expertise in executing various SAP upgrade projects, including non-Unicode to Unicode and version upgrades (e.g., from SAP 4.7 to SAP ERP 6.0) as well as version upgrades along with functional upgrades. We have successfully executed multiple upgrade projects for our clients, many of whom have chosen to combine their upgrades with migration to the cloud. Our clients trust us for these services because of our unique value proposition as a one-stop solution provider for all their needs related to the SAP system at every stage of its lifecycle."
  },


];




export const DedicatedTeam = () => {
  return (
    <React.Fragment>
      <Header />
      <section className="team-section">
        <div className="container">
          <div className="banner-content-wrapper">
            <div className="container">
              <div className="banner-content text-center">
                <h1 className="text-white">
                Accelerate your business automation with SAP on cloud
                </h1>
                <p className="text-white">
                Risepro Soft Solutions has over a decade of experience in providing SAP Advisory and Consulting, Implementation, SAP Infrastructure, SAP Migration, SAP Management and Support services.
                </p>
                <p className="text-white">
                As a trusted partner, we provide SAP workloads on the cloud with a range of benefits including zero downtime, cost controls, elasticity, scalability, and complete managed services. Our offering includes both SAP basis and functional support, bringing the intelligence, security, and reliability of on-premise workloads to the cloud, all of which have been certified by SAP.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{paddingBottom:'0'}}>
        <div className="container">
          <div className="title-block text-center mb-4">
            <h2>SAP Cloud Infrastructure Services</h2>
            <p>
            Enterprises that are looking for cloud adoption are often unsure about what they need to assess and how, when to start, and how to proceed and reap benefits. These need to be key considerations in their cloud strategy for hosting SAP workloads.
            </p>
            <p>
            With its SAP on Cloud Advisory services, Risepro Soft Solutions helps organizations choose the cloud that best fits their SAP environment along with a wide range of SAP Cloud Hosting options, including Migration and Infrastructure Managed Services.
            </p>
            <p>
            We help organizations accelerate their cloud adoption through our multi-cloud offerings andflexibility in deployments that include hosting on Risepro Soft Solutions SAP-certified CloudInfinit grid, and SAP on AWS, Azure, and GCP.
            </p>
          </div>
          <div className="work-grid-wrapper">
            <div className="row">
              {services.map((service) => (
                <div className="col-md-4 mb-4" key={service.id}>
                  <div className="dev-card">
                    <div className="icon">
                      <img src={service.imgSrc} alt={service.altText} />
                    </div>
                    <div className="card-body">
                      <h4 className="card-title">
                        <a href={service.link} title={service.title}>
                          {service.title}
                        </a>
                      </h4>
                      <p className="card-text">{service.description}</p>
                      <p className="text-end text-sm more-content">More <i className="fa fa-arrow-right" style={{color:'#1329e9'}}></i></p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </React.Fragment>
  );
};
