export const SapUser = () => {
  return (
    <section className="sap-user-section">
      <div className="container">
        <div className="row">
          <div className="col-md-6"></div>
          <div className="col-md-6">
            <p className="text-white">
              RiseProSoft Solutions specializes in guiding businesses through
              tool selection based on strategic criteria like Total Cost of
              Ownership (TCO) optimization, Design and Architecture consulting,
              License Optimization, and Business Process Outsourcing. Our
              seasoned SAP consultants bring extensive project experience to
              support you, even with intricate SAP projects. Discover how we can
              streamline your operations and enhance efficiency today.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
