export const CustomDevelopmentData = [
    {
      title: 'What is custom software development?',
      content: `Custom software development, also known as bespoke software development, is a process of designing and developing software that exclusively meets the specific needs of a business or a user group. Commercially available off-the-shelf solutions do not always meet the definite requirements, and this is where custom software development services come into play. Custom software development improves operational efficiency, reduces personnel and training expenses, and allows you to upscale with market demands.`
    },
    {
      title: 'Why should one go for custom software development?',
      content: `Custom software development caters to the unique requirements of a business, which off-the-shelf software cannot fulfill. It provides advantages like increased efficiency by cutting down manual interface, optimizing critical business processes, automating workflows, increasing productivity, better team coordination, and increasing profitability. It also reduces the overall cost of ownership in the long run by providing software modernization and customization. Before opting for a custom software development project, ensure that your business truly needs bespoke software. Custom software development can replace or consolidate your existing solutions, resulting in increased productivity and a competitive advantage.`
    },
    {
      title: 'What technologies are used to develop custom software?',
      content: `There is a wide range of technologies, frameworks, software development methodologies, and tools available to develop custom software. However, making the right choice is often critical as it impacts your business performance and efficiency. We work closely with our clients to understand their business requirements, budget, and future needs and help them select the right technology from the list mentioned here.`
    },
    {
      title: 'Why choose RiseProSoft Solutions for Custom Software Development Service?',
      content: `We are a CMMi Level 3 & Microsoft Gold certified custom software development company with 21+ years of robust IT experience in helping businesses create custom software solutions. Agile development process, DevOps, flexible business engagement models, strong domain competence, latest technology expertise, stringent quality process, and managing intellectual property rights are some of our unique selling points. We have delivered 1800+ custom solutions for companies, and our clients have seen significant return on investment since the implementation of our software.`
    },
    {
      title: 'What are your custom software development services?',
      content: `We provide custom web development, quality assurance, system integration, as well as mobile app development services.`
    },
    {
      title: 'How do you understand client business to propose custom software development?',
      content: `In the initial call discovery phase, our business analysts deep dive into understanding the customer's business to create a requirement document. For this, they conduct workshops with the customer's stakeholders, understand their processes, and research how to achieve their business objectives. This iterative process results in requirement specifications, high-level design, and a project/sprint plan for custom software product development.`
    },
    {
      title: 'Can the customer select the technology stack for custom software development?',
      content: `Yes. Depending on the requirements, our software development team suggests the technology stack considering parameters like existing infrastructure, feature requirements, scalability, future maintenance, budgeting, and open-source alternatives. However, we are always open to the customer's suggestions and strive to achieve the business objectives of custom software development with the customer's choice of technology.`
    },
    {
      title: 'How do you manage changes in requirements?',
      content: `Project requirements may evolve or change during subsequent phases of development. We conduct detailed impact analysis of change requests (CR) in the existing development and keep the customer informed about budget and timeline changes.`
    },
    {
      title: 'Do you provide maintenance of custom software solutions?',
      content: `We are a full-service custom software development company providing design, development, deployment, and maintenance services. Sustaining a software application is equally important as developing it, as it keeps the application healthy and relevant. Our maintenance services include feature enhancements, optimization, bug fixing, and preventive maintenance.`
    },
    {
      title: 'Can I review the code during the development process?',
      content: `Code reviews during development help in minimizing bugs and improving software quality. Clients have complete freedom to review not only the code but also the design of the tailor-made software solutions.`
    },
    {
      title: 'Do you sign an NDA?',
      content: `Yes, before the start of every project, we sign a Non-Disclosure Agreement (NDA) with all our clients. As an ethical software development firm, we also sign NDA agreements with all our software developers to protect customer IPs and data.`
    },
    {
      title: 'What is your policy on intellectual property?',
      content: `Code ownership belongs to the customer once they have remunerated us for the work done.`
    },
    {
      title: 'How do you manage projects?',
      content: `We follow two of the most popular methodologies for custom software development: Agile and Waterfall. We use Agile software development methodology in most of our projects.`
    },
    {
      title: 'What is your typical team size?',
      content: `Team size varies between 2 to 20 depending on the customer's requirements and the size of the project.`
    },
    {
      title: 'Can you take over from my existing vendor?',
      content: `Yes. First, we review your existing application, understand your business domain, and review the code. Based on our preliminary analysis, we provide recommendations and a proposal to take over.`
    },
    {
      title: 'What is the approximate cost of custom software development?',
      content: `The cost of custom software development depends on various factors such as:
        - Size of the project
        - Technology stack
        - Complexity of the project
        - Design specifications
        - Data integration and migration needs
        - Number of resources required and their location.
        
      If you want to get a rough estimate, send us your requirements at info@riseprosoftsolutions.com.`
    }
  ];
  

export const WebDevelopmentData = [
    {
      title: "What kind of web app development services do you provide?",
      content: `
        From two decades of experience as a full-service Web application
        development company, we are capable of offering multiple services
        ranging from
        <ul>
          <li>Web Frontend Development and Design</li>
          <li>Backend Development</li>
          <li>SAAS Applications Development</li>
          <li>Digital Transformation</li>
          <li>Custom Web Application Development Services</li>
          <li>Ecommerce Development</li>
        </ul>
      `,
    },
    {
      title: "What kind of web applications have you developed?",
      content: `
        In the span of 21+ years, we have created more than 1600 web
        applications catering to a wide range of industries like ecommerce,
        healthcare, media, fintech, public sector, logistics, education, retail
        and many more. Many of our applications are being used by small and
        medium enterprises as well as by Fortune 500 companies.
      `,
    },
    {
      title: "What are your preferred technologies for web development?",
      content: `
        When it comes to web app development, there are many options available
        to use. We select the specific frameworks or tools based on the client
        requirements. Some of the preferred Frameworks and tools are:
        <ul>
          <li>Frontend: Angular, React.js, Vue.js, etc.</li>
          <li>Backend: Node.js, .NET, JAVA Frameworks, ROR, PHP Frameworks, etc.</li>
          <li>Database: MySQL, PostgreSQL, MongoDB, Cassandra, Oracle, etc.</li>
          <li>Cloud & DevOps: AWS, Google Cloud, Docker, Jenkins, Kubernetes, Azure, etc.</li>
          <li>Others: JIRA, Postman, Jmeter, etc.</li>
        </ul>
      `,
    },
    {
      title: "Why choose RiseProSoft Solutions as a web app development company?",
      content: `
        RiseProSoft Solutions is a highly competent and trustworthy offshore software
        development firm that specializes in a broad range of software
        development services. Here are a few reasons why you should select
        RiseProSoft Solutions.
        <ul>
          <li>We are a Microsoft gold partner and a CMMI Level-3 firm catering to all levels of enterprise development solutions.</li>
          <li>We have over two decades of consulting and IT services experience in India and across numerous international locations.</li>
          <li>We have over 900+ specialists, including Microsoft certified professionals, SharePoint developers, .NET developers, PHP experts, and other top programming language experts.</li>
          <li>The number of projects we have completed successfully has surpassed 1800.</li>
          <li>Our clients reside in over 36 different nations.</li>
        </ul>
      `,
    },
    {
      title: "What is your procedure for a change Request?",
      content: `
        Project requirements may evolve or change during the subsequent phases
        of development. We do detailed impact analysis of change requests (CR)
        in the existing development and keep customers informed about budget and
        timeline changes.
      `,
    },
    {
      title: "Do you provide maintenance and support services?",
      content: `
        Yes, as a leading custom software development company, we do allow
        maintenance, upgrade and support services for all types of applications.
        It also depends on what type of upgrade you want, be it a bug fix,
        software upgrade or maintenance issue—everything can be taken care of
        in support and maintenance services.
      `,
    },
    {
      title: "Do you sign a Confidentiality Agreement?",
      content: `
        Yes, before the commencement of every project, we sign a Non-Disclosure
        confidentiality agreement with our clients.
      `,
    },
    {
      title: "Who owns the legal rights of the web application developed by RiseProSoft Solutions?",
      content: `
        Once the project development is over and all payments are made, the
        client holds the legal rights of the web application.
      `,
    },
    {
      title: "What is progressive web apps? and how does it work?",
      content: `
        Progressive web apps are a new type of software development process that
        is a combination of ordinary web pages with mobile apps. PWAs also use
        service workers, manifests, and other web platform features in
        combination with progressive enhancements to give users an experience on
        par with native apps. PWA is a new way of adding features to native-like
        bespoke web applications and takes advantage of both native-like mobile
        experience as well as web browsers. The sole intent of developing PWA is
        to bring native apps and web apps to access via search engines.
        <p>There are many benefits that PWA offers like</p>
        <ul>
          <li>They offer faster loading time and are available offline, so they are easily dependable.</li>
          <li>You can do smooth navigation to allow for faster functioning.</li>
          <li>Because it can be launched from the home screen and receive push notifications, it is appealing.</li>
          <li>Updates are quite instant.</li>
          <li>Uses cache memory and offers easier offline navigation.</li>
        </ul>
      `,
    },
    {
      title: "Do you work on cloud technologies?",
      content: `
        Yes. Our team is well experienced in Microsoft Azure and Amazon Web
        Services. We assist customers in creating their cloud strategy,
        migration, and DevOps automation.
      `,
    },
    {
      title: "Are your web apps responsive?",
      content: `
        Yes, all our recent web apps are responsive in design. We use Bootstrap
        and CSS3 to provide responsive features to the application.
      `,
    },
    {
      title: "What are your test methodologies?",
      content: `
        We perform different kinds of software testing depending on project
        requirements and budget. Visit 
        <a
          href="https://www.riseprosoftsolutions.com/testing"
          title="Testing & QA"
        >
          Testing & QA
        </a> 
        to know more about our testing capabilities.
      `,
    },
    {
      title: "How much does it cost to develop a Web Application?",
      content: `
        The cost of an application is never fixed when it comes to custom
        software application development. It is dependent on many contributing
        factors such as:
        <ul>
          <li>App development platform</li>
          <li>App category</li>
          <li>Complexity of app</li>
          <li>Resources needed and their location</li>
          <li>Technology stack</li>
          <li>Project requirements and timeline</li>
        </ul>
        <p>
          If you want to get a rough estimate on how we charge, send us your
          requirements at 
          <a href="mailto: info@riseprosoftsolutions.com">info@riseprosoftsolutions.com</a>
        </p>
      `,
    },
  ];


export const AppDevelopmentData = [
    {
        title: "Why choose RiseProSoft Solutions for Mobile App Development Solutions?",
        content: `
            <p>RiseProSoft Solutions is one of the top mobile app development companies in India. With more than two decades of experience, RiseProSoft Solutions has been developing and deploying mobile apps with ease and proficiency. Our mobile app development services continuously lookout for new technologies and integrate it in developing apps in order to provide forward-thinking solutions and top mobile applications. Our mobile app developers also provide after-release support for your app solution to ensure that even after release it functions continuously and delivers fruitful outcomes.</p>
        `
    },
    {
        title: "How much time is taken for mobile application development?",
        content: `
            <p>The time for developing mobile applications depends on the scope of the app and the features you wish to include. Lesser features take less time and ideally for any mobile app development it takes from a few weeks to months to build a fully-fledged mobile application.</p>
        `
    },
    {
        title: "What do you do to keep the app data safe and secure?",
        content: `
            <p>During the software or app development, we ensure to integrate all security aspects that are required to keep the data and the codes secure from any kind of breach. As a mobile app development company, we also ensure that the backend of the application is also SSL protected that can easily protect your app from any kind of vulnerabilities.</p>
        `
    },
    {
        title: "What is the cost to develop a mobile application?",
        content: `
            <p>The cost of development of any application is dependent on several essential factors such as:</p>
            <ul>
                <li>Development platforms- Cross platform or native or Hybrid</li>
                <li>Technology/Language to be used- Java, C#, Flutter, Xamarin, Swift, Objective C</li>
                <li>Resource Strength- Depending on the type of Project</li>
                <li>App Features and Complexities- Number of Screens, API connections, additional functionalities or any specific requests.</li>
            </ul>
        `
    },
    {
        title: "Will you assist us after the launch of the application?",
        content: `
            <p>Yes, we do provide support post app development and upgrade assistance after the application is launched.</p>
        `
    },
    {
        title: "How can you ensure that my software will have great UI/UX?",
        content: `
            <p>Businesses will always benefit if their apps have intuitive design that anticipates accurate user needs. We are well-equipped with skilled in-house UI/UX Designers with superior design skills to understand client's expectations and give them explicit user experience.</p>
        `
    },
    {
        title: "Who owns the intellectual property?",
        content: `
            <p>Client owns the IP and source code after project completion and payment settlements.</p>
        `
    },
    {
        title: "What is PWA?",
        content: `
            <p>PWA stands for Progressive Web Application. It is an emerging technology built using web technologies like HTML, CSS and JavaScript with the same feel & functionalities of a native application. It helps in developing mobile and web apps which are compatible with any device, version and operating system. It also offers an appealing user experience more than that of a conventional mobile-optimized site. With unique features such as push notification, enhanced security, offline support, faster speed, local data caching and more provides PWA an edge over responsive websites.</p>
        `
    },
    {
        title: "For which platform should we build an application? iOS or Android?",
        content: `
            <p>With the growing popularity of both iPhone and Android smartphones, as an app development company, we recommend you to launch the product on both the platforms. However if you are a start-up and you are confused about which business app to launch first, you need to consider the following 5 factors:</p>
            <ul>
                <li>Audience</li>
                <li>Project Timelines</li>
                <li>Desired App Features</li>
                <li>Budget</li>
                <li>Revenue goal</li>
            </ul>
            <p>These factors will help you make a critical decision on which platform to launch your app first.</p>
        `
    },
    {
        title: "What is the difference between Cross-platform app development and Native app development?",
        content: `
            <p>Key differences between Cross-platform app development and Native app development are as follows:</p>
            <div class="table-responsive">
                <table class="mt-4">
                    <tbody>
                        <tr>
                            <td><strong>Differentiating Factors</strong></td>
                            <td><strong>Native App Development</strong></td>
                            <td><strong>Cross-platform App development</strong></td>
                        </tr>
                        <tr>
                            <td><strong>Development Ease</strong></td>
                            <td>Extremely easy to use and develop</td>
                            <td>Moderate but It’s improving.</td>
                        </tr>
                        <tr>
                            <td><strong>Platforms / Tools</strong></td>
                            <td>Swift for iOS and Java/Kotlin for Android</td>
                            <td>JavaScript for React Native Apps, Dart for Flutter, and C# language for Xamarin</td>
                        </tr>
                        <tr>
                            <td><strong>Market time</strong></td>
                            <td>Slow in comparison to cross-platform ones as you have to create Native apps for each platform.</td>
                            <td>It is a one-time build and execute type of development. You can expect faster time to market and better performance because of using a Single codebase.</td>
                        </tr>
                        <tr>
                            <td><strong>Level of Testing</strong></td>
                            <td>Testing is fast and easier in Native applications</td>
                            <td>In cross-platforms, some frameworks offer simpler testing while with others, it can get complicated.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        `
    },
    {
        title: "What are the benefits of cross-platform app development?",
        content: `
            <p>While you have decided to use cross-platform applications, you must know how cross-platform apps benefit you in multiple ways. Like, if you use cross-platform apps, you have:</p>
            <ul>
                <li>Single code that works for all that improves overall market time</li>
                <li>You have access to many plugins</li>
                <li>It is a cost-effective method for developing apps for multiple platforms</li>
                <li>Reliable and efficient in comparison to Native Apps</li>
                <li>Covers a wide variety of audiences with a single codebase</li>
            </ul>
        `
    }
];

export const ProductDevelopmentData = [
    {
        title: "I have a product idea. How can you help me with it?",
        content: `
            <p>You can prepare comprehensive information for your product development idea and send it to us. Our business development executive will discuss it over emails and online meetings to understand the feasibility of project and align it with our offerings. After that, we will analyse your requirements and create a roadmap to turn your vision into a reality with our services in a specific time-line and budget.</p>
        `
    },
    {
        title: "What about intellectual property rights?",
        content: `
            <p>We sign a Non-Confidentiality agreement with all our Clients before the commencement of any project. Adhering to security standards, we assure that all of the client's information and IP's stay confidential.</p>
        `
    },
    {
        title: "What are the product development steps?",
        content: `
            <p>Software product development includes full life-cycle development from analysing market needs to final product support. It exclusively covers different phases of project development such as:</p>
            <ul>
                <li>Market analysis and idea conceptualization</li>
                <li>Discovery and analysis phase</li>
                <li>Design and architecture development</li>
                <li>Product development using Agile methodology</li>
                <li>Product testing and final release</li>
                <li>Product support and future enhancement</li>
            </ul>
        `
    },
    {
        title: "Why choose RiseProSoft Solutions for software product development?",
        content: `
            <p>RiseProSoft Solutions is a CMMi Level 3 & Microsoft Gold certified custom software development company with 21+ years of robust IT experience in helping businesses develop custom products. Agile development process, DevOps, strong product development experience, wide domain expertise, lower development cost and offshore development center are some of our strengths. We develop unique, effective, and efficient software products at a very competitive rate. Our product development team follows industry best practices and proven methodologies to ensure market success of your product without sacrificing product quality.</p>
        `
    },
    {
        title: "What kind of products have you developed?",
        content: `
            <p>We have developed a variety of white label products for our clients with our diversified industry expertise. Some of our qualitative products to name includes ERP, POS, CRM, LMS, DMS and SaaS based solutions.</p>
        `
    },
    {
        title: "How much does it cost to develop a product?",
        content: `
            <p>Cost depends on various factors like size of the project, number of resources, technology stack, product complexity and development process. Please connect with our business development team for more insight on this.</p>
        `
    },
    {
        title: "Which technology do you use for product development?",
        content: `
            <p>Based on the project requirements, we analyse the product and suggest the right technology. Our talented software engineers are equipped with the latest technologies and have extensive experience in the development of innovative products.</p>
        `
    }
];

export const TestingDevelopmentData = [
  {
      title: "What are Software Testing Services?",
      content: `
          <p>Software Testing is a process of identifying errors or glitches in a software development process. It includes a wide range of Software Testing services such as Performance and Load Testing, Regression Testing, Web Testing, Integration Testing, Unit Testing, Accessibility Testing, Localization Testing, Exploratory Testing, User acceptance Testing, API Testing, Mobile Application Testing, Usability Testing, Functionality Testing, UI Testing, Compatibility and Security Testing Services. All these types of Software Testing ensures the product is bug-free, up-to-date, and will perform at its best.</p>
      `
  },
  {
      title: "Why Choose RiseProSoft Solutions for QA and Software Testing Services?",
      content: `
          <p>Our more than two decades of industry expertise has helped businesses to develop unique and cutting-edge technology solutions. RiseProSoft Solutions has skilled and knowledgeable workforce that aims to provide high-quality QA services. Our consistent assistance to clients enables them to monitor their application development in real-time at all stages, delivering high-quality software solutions.</p>
      `
  },
  {
      title: "What is your approach with Software Quality Assurance Services as a Software QA Company?",
      content: `
          <p>Our only aim is to meet customer expectations by offering best-in-class QA services. At each stage of the product development process, our testing teams delivers QA and software testing services. During the planning stage, we do a detailed study of your business requirements and provide ideas on how to execute them &amp; develop a test plan. It is part of our approach that helps us to keep within our clients' budgets and time frames.</p>
      `
  },
  {
      title: "What is the difference between QA and Testing?",
      content: `
          <p>Quality Assurance and Software testing are often confusing for businesses to understand because both of these methods aim at the same Bug-free and performing application. The basic difference between the two is Quality assurance is a process to make sure the project is conforming to the expectations of the stakeholders while the Software testing process involves techniques and methods to identify glitches in software.</p>
      `
  },
  {
      title: "What are the benefits of working with an outsourced QA and Testing services Team?",
      content: `
          <p>There are plenty of benefits of working with a dedicated team of software testers and engineers. It is a cost-effective process as you don’t have to invest in infrastructure, resources, hiring process and other time-consuming business processes. Other benefits include:</p>
          <ul>
              <li>Domain expertise of the team members.</li>
              <li>Team collaboration.</li>
              <li>Agile approach.</li>
              <li>Software security and maintain standards.</li>
          </ul>
      `
  },
  {
      title: "What are the steps involved in performing mobile app testing?",
      content: `
          <p>There are various steps followed by mobile app testing companies to test applications:</p>
          <ul>
              <li>Step 1: Design a Roadmap.</li>
              <li>Step 2: Identify the stages and types of testing.
                  <ul>
                      <li>Stage i: Manual or Automated Testing</li>
                      <li>Stage ii: Regression Testing</li>
                      <li>Stage iii: Acceptance Testing</li>
                      <li>Stage iv: Performance Testing</li>
                      <li>Stage v: Any other</li>
                  </ul>
              </li>
              <li>Step 3: Test case Preparation for each stage.</li>
              <li>Step 4: Script Design and Execution</li>
              <li>Step 5: Complete System Testing Prior Official Launch</li>
          </ul>
      `
  },
  {
      title: "How do you perform mobile app usability testing?",
      content: `
          <p>Evaluating a mobile application with real time users is called Mobile app usability testing. Our mobile app testing services consists of various testing methods.</p>
          <p>Following are the steps for usability testing:</p>
          <ul>
              <li>Step-1: Define the Goals of Usability Testing.</li>
              <li>Step-2: Set the Tasks for Users.</li>
              <li>Step-3: Select the Testing Approach.</li>
              <li>Step-4: Enlist the Test Subjects/Participants.</li>
              <li>Step-5: Perform the Usability Test.</li>
              <li>Step-6: Collect Data and Examine the Results.</li>
              <li>Step-7: Gather User Feedback for Improvements.</li>
              <li>Step-8: Prepare for the Next Round of Usability Testing.</li>
          </ul>
      `
  },
  {
      title: "What is the difference between functional and non-functional testing?",
      content: `
          <p>Functional Testing ensures that the software solution satisfies the criteria provided by the business as their requirements and User Stories. Non-Functional Testing focuses on performance, reliability, infrastructure, and other related issues.</p>
      `
  },
  {
      title: "Do you sign an NDA?",
      content: `
          <p>Yes before the start of every project, we sign Non-Disclosure Agreement with all our Clients.</p>
      `
  },
  {
      title: "What is your policy on intellectual property?",
      content: `
          <p>Code ownership belongs to the customer once they have completely remunerated us for the work done.</p>
      `
  },
  {
      title: "What is your typical testing team size?",
      content: `
          <p>Team size varies between 2 to 20 depending on customer's requirements and size of project.</p>
      `
  },
  {
      title: "Can you take over from my existing vendor?",
      content: `
          <p>Yes. Firstly, we go through your existing application, understand your business domain and review the code. Based on our preliminary analysis, we provide recommendations and proposal to takeover.</p>
      `
  }
];
