import React from "react";
import { Header } from "../header";
import { Footer } from "../home/footer";
import Accordion from "./accordian";
import { CustomDevelopmentData } from "./customdev/accordianData";
import { Hireteam } from "./hireteam";
import TechnologyStackGrid from "./techstack/techStack";
import { TechnologyStackData } from "./technicalstacks";
import { Link } from "react-router-dom";

const services = [
  {
    id: 1,
    imgSrc: "images/service-icon-1.webp",
    title: "Bespoke Software Development",
    description: "Superior custom software development services combined with expert resources, technical prowess to strengthen overall business strategy. Our prototyping approach and robust custom development plan create custom applications from scratch across mobile, web, and cloud platforms ensuring seamless integration, flexibility, and business profitability."
  },
  {
    id: 2,
    imgSrc: "images/service-icon-2.webp",
    title: "Application Migration and Re-Engineering",
    description: "Modernize existing software solutions and boost performance by redesigning, refactoring, or migration facilities to fully customized functionally-rich applications. Our software engineers ensure flawless re-engineering of software with advanced enhancements of new features, improved UI/UX, and modern security-based on cutting-edge technologies."
  },
  {
    id: 3,
    imgSrc: "images/service-icon-3.webp",
    title: "Software Application Maintenance",
    description: "Explore the maximum potential of your software application by fine-tuning it with regular software maintenance, upgrades and performance optimization. Our custom software development services enable you to transparently manage all the post-development activities with periodic software updates and real-time issue resolution ensuring high-performing apps at ease."
  },
  {
    id: 4,
    imgSrc: "images/service-icon-4.webp",
    title: "SaaS Applications",
    description: "Leverage our multi-year experience in building commercial SaaS-based applications to address your specific business challenges and achieve higher productivity, minimum risks, competitive advantage and elevated customer experience. We are experts in creating SaaS applications using various technologies and frameworks using cloud and serverless."
  },
  {
    id: 5,
    imgSrc: "images/service-icon-5.webp",
    title: "Application Integration",
    description: "End-to-end application integration services to optimize workflow between disparate business sources that hinders business progress. Our software integration engineers offer state of the art services between on-premise cloud or offline data environments with customization to ensure smoother and flexible business operations with lower integration costs."
  },
  {
    id: 6,
    imgSrc: "images/service-icon-6.webp",
    title: "Maintenance & Support",
    description: "Custom software maintenance and support are crucial in the digital age. Our custom software development company offers comprehensive software maintenance services that cover bug fixes, software upgrades, performance optimization, technical support, and customer service to ensure uninterrupted software operations."
  }
];

export const Customdev = () => {
  return (
    <React.Fragment>
      <Header />
      <section className="custom-head-section">
        <div className="container">
          <div className="banner-content-wrapper">
            <div className="container">
              <div className="banner-content text-center">
                <h1 className="text-white">Custom Software Development Company</h1>
                <p className="text-white">
                  Achieve a competitive edge for your unique and complex
                  business needs with RiseProSoft Solutions. Harness our capability to
                  develop agile and reliable custom software solutions &amp;
                  applications that expedite the digital transformation
                  journey of businesses with technology innovation,
                  scalability, and agility.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{paddingBottom:'0'}}>
        <div className="container">
          <div className="title-block text-center mb-4">
            <h2>Custom Software Development Services</h2>
            <p>
              Our tailor-made business software solutions comprehensively
              offer services to multiple businesses
            </p>
          </div>
          <div className="work-grid-wrapper">
            <div className="row">
              {services.map((service) => (
                <div className="col-md-4 mb-4" key={service.id}>
                 <div className="dev-card">
                  <div className="icon">
                    <img src={service.imgSrc} alt={service.altText} />
                  </div>
                  <div className="card-body">
                    <h4 className="card-title">
                      <a href={service.link} title={service.title}>
                        {service.title}
                      </a>
                    </h4>
                    <p className="card-text">
                      {service.description}
                    </p>
                    <p className="text-end text-sm more-content">More <i className="fa fa-arrow-right" style={{color:'#1329e9'}}></i></p>
                  </div>
                </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="risepro-technology-stack-section">
  <div className="container">
    <h2 className="text-center mb-3">Technology Stack</h2>
    <TechnologyStackGrid data={TechnologyStackData} />
  </div>
</section>
<Hireteam title={'Custom Software Developers'} />
<Contacttag />
<section className="risepro-faq-section pb-0">
  <div className="container">
    <h2 className="mb-5 text-center">Custom Software Development FAQs</h2>
    <Accordion items={CustomDevelopmentData} />
  </div>
</section>


<div>

</div>

      <Footer/>
    </React.Fragment>
  );
};


export const Contacttag =()=> {
  return(
    <section className="pb-0">
  <div className="container">
    <div className="d-flex align-items-center gap-2 justify-content-between">
      <div className="content-block">
        <h2>Looking for a web development company for your next project?</h2>
      </div>
      <div className="btn-block">
        <div className="btn-inner">
          
          <button className="btn custom-contactbtn" style={{background:'#2236E5'}}><Link to={'/contactus'}>Contact Us <i className="fa fa-angle-right"></i></Link></button>
        </div>
      </div>
    </div>
  </div>
</section>
  )
}