import Carousel from "react-multi-carousel";

export const Insights = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1200, min: 1080 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 1080, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  const slides = [
    {
      logo: 'images/saprounded.png',
      description:
        " Introduction of new and upgraded SAP systems (ABAP, Java)",
      delay: 100,
    },
    {
      logo: 'images/saprounded.png',
      description:
        "SAP Consulting focused on technology",
      delay: 100,
    },
    {
      logo: 'images/saprounded.png',
      description:
        "Migration of SAP system landscapes (Ex: HANA, S/4HANA)",
      delay: 100,
    },
  ];
  return (
    <section className="insight-section">
      <div className="container">
        <div className="row">
          <div className="text-center">
            <h1>Architectural and Technological Insights</h1>
            <p>A Comprehensive Look at Our SAP Service Offerings</p>
          </div>
          <div className="mt-4">
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={1000}
              keyBoardControl={true}
              customTransition="transform 500ms ease-in-out"
              transitionDuration={1500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              {slides.map((slide, index) => (
                <div
                  key={index}
                  className="text-center"
                  data-aos="zoom-in"
                  data-aos-delay={slide.delay}
                >
                  <div className="icon-box">
                    <div className="mb-3"><img src={slide.logo} alt="" /></div>
                    <p>{slide.description}</p>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
};
