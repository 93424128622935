export const Hireteam =({title})=> {
    return(
<section className="risepro-cta-block pb-0" >
  <div className="container">
    <div className="risepro-inner-container">
      <div className="risepro-cta-block-title-wrapper">
        <div className="risepro-cta-block-title-left-block">
          <h2>Hire a dedicated team of {title}</h2>
          <p>
            Get access to specialized set of skilled resources for your unique
            requirements on custom software projects
          </p>
        </div>
       
      </div>
    </div>
  </div>
  <div className="container mt-5">
    <div className="risepro-inner-container">
      <div className="row">
        <div className="col-xl-4 col-lg-6 col-md-12">
          <div className="risepro-tools-technology-item-inner">
            <div
              className="risepro-tools-technology-content"
              style={{ height: "117.672px" }}
            >
              <h3>Flawless Design</h3>
              <p>
                Enrich your digital experience with top-notch designs and
                practical creativity
              </p>
            </div>
            <div className="risepro-logo-grid design-tools d-flex justify-content-center">
              <img
                data-src="images/design-tools.svg"
                title="Design Tools"
                alt="Design Tools"
                className="js-lazy-image js-lazy-image--handled"
                src="images/design-tools.svg"
              />
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-12">
          <div className="risepro-tools-technology-item-inner">
            <div
              className="risepro-tools-technology-content"
              style={{ height: "117.672px" }}
            >
              <h3>Effective Code</h3>
              <p>
                Employ suitable framework and code effectively to individual
                business needs
              </p>
            </div>
            <div className="risepro-logo-grid design-tools d-flex justify-content-center">
              <img
                data-src="images/java-code-tools.svg"
                title="Code Tools"
                alt="Code Tools"
                className="js-lazy-image js-lazy-image--handled"
                src="images/java-code-tools.svg"
              />
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-12">
          <div className="risepro-tools-technology-item-inner">
            <div
              className="risepro-tools-technology-content"
              style={{ height: "117.672px" }}
            >
              <h3>Effortless Communication</h3>
              <p>
                Hassle-free Communicate with our developers for daily status and
                update
              </p>
            </div>
            <div className="risepro-logo-grid design-tools d-flex justify-content-center">
              <img
                data-src="images/communication-code.svg"
                title="Communication Tools"
                alt="Communication Tools"
                className="js-lazy-image js-lazy-image--handled"
                src="images/communication-code.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    )
}