import { Link } from "react-router-dom"

export const Homesec=()=> {
    return(
        <section className="home-section tech-bg">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                    <h1 style={{color:'#fff',maxWidth:'935px',width:'100%',lineHeight:'normal'}}>Transform Your Business with RiseProSoft Solutions: Your Trusted Expert in SAP Consulting, Web Design, Mobile App Development and Comprehensive Digital Solutions. </h1>
                    <button className="btn contactbtn" >
                        <Link to="/contactus"> Contact us <i className="fa fa-arrow-right " style={{color:'#1329e9'}}></i></Link>
                    </button>
                    </div>
                    {/* <div className="col-md-5">
                        <img src="images/sap.png" alt="" style={{width:'100%'}} className="mt-5" />
                    </div> */}
                </div>
            </div>
        </section>
    )
}