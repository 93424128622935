import React from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <React.Fragment>
      <footer className="footer-08 mt-5">
      <div className="container">
        <div className="row" style={{ alignItems: "start" }}>
          <div className="col-md-9 py-5">
            <div className="row" style={{ alignItems: "start" }}>
              <div
                className="col-md-4 mb-md-0 mb-4 ml-3"
                style={{ paddingLeft: "65px" }}
              >
                <img src="images/logo.png" alt="" style={{ width: "180px" }} />
                <p className="mt-5 mb-5">
                  we have a dedicated team of recruiters, headhunters,
                  and consultants that pay attention to even the smallest of the
                  details to create efficie employer-candidate connections.
                </p>
                <button className="btn">Know more</button>
              </div>
              <div className="col-md-8">
                <div
                  className="row justify-content-center"
                  style={{ alignItems: "start" }}
                >
                  <div className="col-md-12">
                    <div className="row" style={{ alignItems: "start" }}>
                      <div className="col-md-4 mb-md-0 mb-4">
                        <h2 className="footer-heading">Services</h2>
                        <ul className="list-unstyled">
                          <li>
                            <Link to="/" className="py-1 d-block">
                              Executive Search
                            </Link>
                          </li>
                          <li>
                            <Link to="/" className="py-1 d-block">
                              CXO Hiring
                            </Link>
                          </li>
                          <li>
                            <Link to="/" className="py-1 d-block">
                              Turnkey Recruitment
                            </Link>
                          </li>
                          <li>
                            <Link to="/" className="py-1 d-block">
                              Interim Management
                            </Link>
                          </li>
                          <li>
                            <Link to="/" className="py-1 d-block">
                              HR Consulting
                            </Link>
                          </li>
                          <li>
                            <Link to="/" className="py-1 d-block">
                              Resume Writing
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-4 mb-md-0 mb-4">
                        <h2 className="footer-heading">Industry Verticals</h2>
                        <ul className="list-unstyled">
                          <li>
                            <Link to="/" className="py-1 d-block">
                              Banking & Insurance
                            </Link>
                          </li>
                          <li>
                            <Link to="/" className="py-1 d-block">
                              Financial Services & Consulting
                            </Link>
                          </li>
                          <li>
                            <Link to="/" className="py-1 d-block">
                              Pharmaceutical & Healthcare
                            </Link>
                          </li>
                          <li>
                            <Link to="/" className="py-1 d-block">
                              Information Technology
                            </Link>
                          </li>
                         
                          <li>
                            <Link to="/" className="py-1 d-block">
                              Infrastructure & Construction
                            </Link>
                          </li>
                          <li>
                            <Link to="/" className="py-1 d-block">
                              Telecommunication
                            </Link>
                          </li>
                        
                        </ul>
                      </div>
                      <div className="col-md-4 mb-md-0 mb-4">
                        <h2 className="footer-heading">Contact Details</h2>
                        <ul className="list-unstyled">
                          <li>
                            <Link to="/" className="py-1 d-block">
                              Careers
                            </Link>
                          </li>
                          <li>
                            <Link to="/" className="py-1 d-block">
                              Contact Us
                            </Link>
                          </li>
                          <li>
                            <Link to="/" className="py-1 d-block">
                              Success Stories
                            </Link>
                          </li>
                          <li>
                            <Link to="/" className="py-1 d-block">
                              Privacy Policy
                            </Link>
                          </li>
                          <li>
                            <Link to="/" className="py-1 d-block">
                              Terms & Conditions
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          <div className="col-md-3 aside-stretch-right pl-lg-5">
            <h2 className="footer-heading footer-heading-white text-white">
              <img src="images/phone.svg" alt="" /> Let’s Talk
            </h2>
            <div className="lets-talk">
            <div className="d-flex gap-2">
              <img src="images/mail.svg" alt="" style={{ width: "40px" }} />
              <div>
                <b>Business enquiries</b>
                <p className="m-0">
                  <a href="mailto:info@riseprosoftsolutions.com">
                    info@riseprosoftsolutions.com
                  </a>
                </p>
              </div>
            </div>
            <div className="d-flex gap-2 mt-4">
              <img src="images/telegram.svg" alt="" style={{ width: "40px" }} />
              <div>
                <b>Business enquiries </b>
                <p className="m-0">
                  <a href="mailto:info@riseprosoftsolutions.com">
                    @riseprosoft
                  </a>
                </p>
              </div>
            </div>
            <div className="d-flex gap-2 mt-4">
              <img src="images/linkedin.png" alt="" style={{ width: "48px" }} />
              <img src="images/facebook.png" alt="" style={{ width: "48px" }} />
              <img
                src="images/instagram.png"
                alt=""
                style={{ width: "48px" }}
              />
            </div>
            <div className="input-group mt-4">
             
              <input
                type="text"
                className="form-control"
                id="inlineFormInputGroup"
                placeholder="Enter Your Email"
              />
               <div className="input-group-prepend">
                <div className="input-group-text">submit</div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <div className="copy-right-section">
       <div className="container">
       <div className="row mt-md-5" style={{ alignItems: "start" }}>
              <div className="col-md-12">
                <p className="copyright">
                  Copyright ©{year} All rights reserved
                </p>
              </div>
            </div>
       </div>
    </div>
    </React.Fragment>
  );
};
