import React, { useState } from "react";
import { Header } from "./header";
import { Footer } from "./home/footer";

export const Contactus = () => {
  const [formValues, setFormValues] = useState({
    name_contact: "",
    email_contact: "",
    PhoneNumber: "",
    company_name: "",
    DescriptionContact: "",
    file_attach_contact: null,
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    console.log(files)
    setFormValues({
      ...formValues,
      [name]: files ? files[0] : value,
    });
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formValues.name_contact) formErrors.name_contact = "Name is required";
    if (!formValues.email_contact) {
      formErrors.email_contact = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formValues.email_contact)) {
      formErrors.email_contact = "Invalid email address";
    }
    if (!formValues.PhoneNumber) formErrors.PhoneNumber = "Phone number is required";
    if (!formValues.company_name) formErrors.company_name = "Company name is required";
    if (!formValues.DescriptionContact) formErrors.DescriptionContact = "Description is required";
    if (formValues.DescriptionContact.length > 500) formErrors.DescriptionContact = "Description can't be longer than 500 characters";
    if (formValues.file_attach_contact && !["application/msword", "application/vnd.ms-excel", "application/pdf", "application/zip"].includes(formValues.file_attach_contact.type)) {
      formErrors.file_attach_contact = "Unsupported file format";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Handle form submission
      console.log(formValues);
      // You might want to send the data to a server here
    }
  };

  return (
    <React.Fragment>
      <Header />
      <section className="contact-section">
        <div className="container">
          <div className="contact-banner-wrapper row" style={{ alignItems: 'start' }}>
            <div className="left-col inner-col col-md-6">
              <div className="left-col-wrapper">
                <div className="heading-block">
                  <h1 className="text-white">Contact us</h1>
                  <p className="text-white">
                    Please send us your requirements and we'll get back to you at the
                    earliest.
                  </p>
                </div>
                <div className="content-block">
                  <ul className="inquiry-listing">
                    <li className="text-white">
                      <span>Inquiry: </span>
                      <a href="mailto:info@riseprosoftsolutions.com" title="Mail Us">
                        info@riseprosoftsolutions.com
                      </a>
                    </li>
                    <li className="text-white">
                      <span>Work with us: </span>
                      <a href="mailto:career@riseprosoftsolutions.com" title="Mail Us">
                        career@riseprosoftsolutions.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="right-col inner-col col-md-6">
              <div className="contact-form-wrapper">
                <div className="form-wrapper">
                  <div className="title-wrapper">
                    <h2>Get in touch</h2>
                  </div>
                  <form
                    autoComplete="off"
                    id="ContactForm"
                    className="contact-form"
                    onSubmit={handleSubmit}
                    noValidate
                  >
                    <div className="form-group name-div">
                      <label htmlFor="name">
                        Name
                      </label>
                      <input
                        type="text"
                        name="name_contact"
                        id="name_contact"
                        className="form-control your-name"
                        placeholder="Name"
                        value={formValues.name_contact}
                        onChange={handleChange}
                      />
                      {errors.name_contact && <div className="error">{errors.name_contact}</div>}
                    </div>
                    <div className="form-group email-div">
                      <label htmlFor="mail">
                        Email Address
                      </label>
                      <input
                        type="email"
                        name="email_contact"
                        id="mail"
                        className="form-control your-email"
                        placeholder="Email Address"
                        value={formValues.email_contact}
                        onChange={handleChange}
                      />
                      {errors.email_contact && <div className="error">{errors.email_contact}</div>}
                    </div>
                    <div className="form-group number-div">
                      <label htmlFor="phnumber_contact">
                        Phone Number
                      </label>
                      <input
                        type="text"
                        name="PhoneNumber"
                        id="phnumber_contact"
                        className="form-control your-number"
                        placeholder="Phone Number"
                        value={formValues.PhoneNumber}
                        onChange={handleChange}
                      />
                      {errors.PhoneNumber && <div className="error">{errors.PhoneNumber}</div>}
                    </div>
                    <div className="form-group company-div">
                      <label htmlFor="company_name">
                        Company Name
                      </label>
                      <input
                        type="text"
                        name="company_name"
                        id="company_name"
                        className="form-control company-name"
                        placeholder="Company Name"
                        value={formValues.company_name}
                        onChange={handleChange}
                      />
                      {errors.company_name && <div className="error">{errors.company_name}</div>}
                    </div>
                    <div className="form-group document-div">
                      <label htmlFor="document">
                        Upload Documents
                      </label>
                      <input
                        type="file"
                        name="file_attach_contact"
                        id="document"
                        className="form-control your-document"
                        multiple
                        accept=".doc, .docx, .ppt, .pptx, .xlsx, .xls, .pdf, .zip, .txt"
                        onChange={handleChange}
                      />
                      {errors.file_attach_contact && <div className="error">{errors.file_attach_contact}</div>}
                    </div>
                    <div className="form-group desc-div">
                      <label htmlFor="description">
                        Message
                      </label>
                      <textarea
                        id="description"
                        className="form-control"
                        maxLength={500}
                        name="DescriptionContact"
                        placeholder="Description"
                        value={formValues.DescriptionContact}
                        onChange={handleChange}
                      />
                      {errors.DescriptionContact && <div className="error">{errors.DescriptionContact}</div>}
                    </div>
                    <div className="btn-wrapper mt-4">
                      <div className="submit-btn-wrapper">
                        <button type="submit" className="btn w-100" id="submit">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </React.Fragment>
  );
};
