import React from 'react';

const TechStackItem = ({ title, icon, alt, stack }) => (
  <div className="grid-item col-md-12 col-lg-6 col-xl-4">
    <div className="grid-inner">
      <h3>
        <span>
          <img
            src={icon}
            alt={alt}
            className="js-lazy-image js-lazy-image--handled"
          />
        </span>
        {title}
      </h3>
      <div className="tech-stack-list-wrapper">
        <ul style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)',}}>
          {stack.map((tech, index) => (
            <li key={index}>{tech}</li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

const TechnologyStackGrid = ({ data }) => (
  <div className="technology-stack-grid-new row" style={{ alignItems: 'start' }}>
    {data.map((item, index) => (
      <TechStackItem 
        key={index} 
        title={item.title} 
        icon={item.icon} 
        alt={item.alt} 
        stack={item.stack} 
      />
    ))}
  </div>
);

export default TechnologyStackGrid;
