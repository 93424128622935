export const TechnologyStackData = [
    {
      title: "Backend",
      icon: "images/tech_backend.svg",
      alt: "tech_backend",
      stack: [
        ".NET",
        "Java",
        "Node",
        "PHP",
        "Ruby on Rails"
      ]
    },
    {
      title: "Frontend",
      icon: "images/frontend-orange.svg",
      alt: "Frontend",
      stack: [
        "Angular",
        "React",
        "Vue"
      ]
    },
    {
      title: "Database",
      icon: "images/database-blue.svg",
      alt: "Database",
      stack: [
        "SQL Server",
        "PostgreSQL",
        "MySQL",
        "MongoDB",
        "Azure SQL",
        "Amazon RDS",
        "Oracle",
        "Cassandra",
        "Elasticsearch",
        "Dynamo"
      ]
    },
    {
      title: "Mobility",
      icon: "images/tech_mobility.svg",
      alt: "tech_mobility",
      stack: [
        "iOS",
        "Android",
        "React Native",
        "Flutter",
        "Ionic"
      ]
    },
    {
      title: "Cloud & DevOps",
      icon: "images/cloud-devops-pink.svg",
      alt: "DevOps",
      stack: [
        "AWS",
        "Azure",
        "Google Cloud",
        "Docker",
        "Kubernetes",
        "Jenkins",
        "Terraform"
      ]
    },
    {
      title: "Testing",
      icon: "images/qa-testing-green.svg",
      alt: "Testing",
      stack: [
        "Postman",
        "Selenium",
        "TestRail",
        "Apache JMeter",
        "Katalon Studio",
        "Jest",
        "OWASP ZAP",
        "JUnit, NUnit",
        "BrowserStack",
        "Jasmine, Mocha, Chai"
      ]
    },
    {
      title: "Others",
      icon: "images/other-tools-pink.svg",
      alt: "tech_others",
      stack: [
        "SharePoint",
        "Apache Kafka",
        "BizTalk",
        "Power BI"
      ]
    }
  ];
  
  export const AppTechnologyStackData = [
    {
      title: "Backend",
      icon: "images/tech_backend.svg",
      alt: "frameworks",
      stack: [".NET", "Java", "PHP", "Node", "Ruby on Rails"],
    },
    {
      title: "Front End",
      icon: "images/frontend-orange.svg",
      alt: "FrontEnd",
      stack: [
        "ObjectiveC",
        "Swift",
        "Java",
        "Kotlin",
        "Coroutines Kotlin",
        "RxKotlin",
        "React Native",
        "Dart",
        "Xaml",
      ],
    },
    {
      title: "Database",
      icon: "images/database-red.svg",
      alt: "database",
      stack: ["SQLite", "Realm", "CoreData", "Firebase"],
    },
    {
      title: "Project Management Tools",
      icon: "images/project-management-blue.svg",
      alt: "project-management",
      stack: ["Jira", "Slack", "Trello", "Microsoft Team"],
    },
    {
      title: "DevOps",
      icon: "images/cloud-devops-pink.svg",
      alt: "DevOps",
      stack: ["CI/CD", "GitHub Actions"],
    },
    {
      title: "Testing",
      icon: "images/qa-testing-green.svg",
      alt: "testing",
      stack: ["Appium", "Katalon Test Studio", "BrowserStack"],
    },
  ];
  

  export const ProductTechnologyStackData = [
    {
      title: "Backend",
      icon: "images/tech_backend.svg",
      alt: "frameworks",
      stack: [".NET", "Java", "Node", "PHP", "Ruby on Rails"],
    },
    {
      title: "Frontend",
      icon: "images/frontend-orange.svg",
      alt: "Frontend",
      stack: ["Angular", "React", "Vue"],
    },
    {
      title: "Database",
      icon: "images/database-red.svg",
      alt: "Database",
      stack: [
        "SQL Server",
        "PostgreSQL",
        "MySQL",
        "MongoDB",
        "Azure SQL",
        "Amazon RDS",
        "Oracle",
        "Cassandra",
        "Elasticsearch",
        "Dynamo",
      ],
    },
    {
      title: "Mobility",
      icon: "images/tech_mobility.svg",
      alt: "Mobility",
      stack: ["iOS", "Android", "React Native", "Flutter", "Ionic"],
    },
    {
      title: "Cloud & DevOps",
      icon: "images/cloud-devops-pink.svg",
      alt: "DevOps",
      stack: [
        "AWS",
        "Azure",
        "Google Cloud",
        "Docker",
        "Kubernetes",
        "Jenkins",
        "Terraform",
      ],
    },
    {
      title: "Testing",
      icon: "images/qa-testing-green.svg",
      alt: "Testing",
      stack: [
        "Selenium",
        "Katalon Studio",
        "Apache JMeter",
        "TestRail",
        "Postman",
        "BrowserStack",
        "OWASP ZAP",
        "JUnit, NUnit",
        "Jest",
        "Jasmine, Mocha, Chai",
      ],
    },
    {
      title: "Others",
      icon: "images/other-tools-pink.svg",
      alt: "Others",
      stack: ["SharePoint", "BizTalk", "Power BI", "Apache Kafka"],
    },
  ];
  

  export const TestingTechnologyStackData = [
    {
      title: "Web",
      icon: "images/web.svg",
      alt: "frameworks",
      stack: ["Selenium", "Katalon Test Studio", "Nightwatch.js", "Jasmine"],
    },
    {
      title: "Mobile Testing",
      icon: "images/mobile_testing.svg",
      alt: "apache-foundation",
      stack: ["Appium", "Katalon Test Studio"],
    },
    {
      title: "API Testing",
      icon: "images/api_testing.svg",
      alt: "project-management",
      stack: ["Rest assured", "Postman", "SoapUI"],
    },
    {
      title: "Test Management",
      icon: "images/test_management.svg",
      alt: "database",
      stack: ["TestRail", "Trello", "Jira", "Pivotal Tracker", "HP ALM"],
    },
    {
      title: "Performance Testing",
      icon: "images/performance_testing.svg",
      alt: "DevOps",
      stack: ["Apache JMeter", "Blazemeter"],
    },
    {
      title: "BDD",
      icon: "images/FrontEnd.svg",
      alt: "FrontEnd",
      stack: ["Cucumber", "Specflow"],
    },
    {
      title: "Security",
      icon: "images/security.svg",
      alt: "bi-reporting",
      stack: ["OWASP Zap"],
    },
    {
      title: "Cloud",
      icon: "images/cloud.svg",
      alt: "database",
      stack: ["Browser Stack"],
    },
  ];
  