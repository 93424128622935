export const TrustSection = () => {
  const repeatedContent = [
    { id: 1, text: "Introduction of new and upgraded SAP systems (ABAP, Java)",logo: 'images/saprounded.png' },
    { id: 2, text: "Introduction of new and upgraded SAP systems (ABAP, Java)",logo: 'images/saprounded.png' },
    { id: 3, text: "Introduction of new and upgraded SAP systems (ABAP, Java)",logo: 'images/saprounded.png' },
    { id: 4, text: "Introduction of new and upgraded SAP systems (ABAP, Java)",logo: 'images/saprounded.png' },
    { id: 5, text: "Introduction of new and upgraded SAP systems (ABAP, Java)",logo: 'images/saprounded.png' },
    { id: 6, text: "Introduction of new and upgraded SAP systems (ABAP, Java)",logo: 'images/saprounded.png' },
    { id: 7, text: "Introduction of new and upgraded SAP systems (ABAP, Java)",logo: 'images/saprounded.png' },
    { id: 8, text: "Introduction of new and upgraded SAP systems (ABAP, Java)",logo: 'images/saprounded.png' }
  ];
  return (
    <section className="">
      <div className="container">
      <div className="text-center mb-4">
          <h1>Key Differentiators of RiseProSoft Solutions</h1>
          <p>Harnessing Expertise and Transparency to Elevate SAP Practice</p>
        </div>
       <div className="mt-5">
       <div className="row">
          {repeatedContent.map(item => (
            <div key={item.id} className="col-xl-3 col-lg-6 col-md-6 mb-4">
              <div className="m-3 text-center">
              <img src={item.logo} alt="" className="mb-3"/>
              <p>{item.text}</p>
              </div>
            </div>
          ))}
        </div>
       </div>
      </div>
    </section>
  );
};
