import React from "react";
import { Header } from "../header";
import { Footer } from "../home/footer";
import { Contacttag } from "./customdev";
import Accordion from "./accordian";
import { AppDevelopmentData } from "./customdev/accordianData";
import { Hireteam } from "./hireteam";
import { AppTechnologyStackData } from "./technicalstacks";
import TechnologyStackGrid from "./techstack/techStack";

const services = [
  {
    id: 1,
    imgSrc: "images/service-icon-1.webp",
    title: "Design & Prototyping of Apps",
    description: "Our mobile app designers can assist you in prototyping and creating a tailor-made mobile application. We offer an intuitive user interface and experience that ensures heightened engagement and a higher ROI.",
    features: ["Conceptualize", "Visualize", "Prototype"]
  },
  {
    id: 2,
    imgSrc: "images/service-icon-2.webp",
    title: "Native App development",
    description: "Our Native app developers, including iOS and Android experts, have extensive experience creating unique and secure applications for specific platforms. We tailor each app to align with your objectives and goals.",
    features: ["Platform-Specific", "Hardware Integration", "App Optimization"]
  },
  {
    id: 3,
    imgSrc: "images/service-icon-3.webp",
    title: "Cross-Platform App development",
    description: "Our cross-platform app developers specialize in creating hybrid applications using technologies such as Flutter and React Native. We craft cost-effective solutions for businesses across various domains and sizes.",
    features: ["Reusable Code base", "Hardware Acceleration", "Rapid Development"]
  }
];



export const Appdev = () => {
  return (
    <React.Fragment>
      <Header />
      <section className="appdev-section">
        <div className="container">
          <div className="banner-content-wrapper">
            <div className="container">
              <div className="banner-content text-center">
                <h1 className="text-white">
                App Development Company
                </h1>
                <p className="text-white">
                With over 21 years of experience crafting progressive native and cross-platform applications, RiseProSoft Solutions stands as a leading mobile app development company. Our seasoned developers, with a proven track record, excel in creating user-centric Android, iOS, and Hybrid apps. We can bring your vision to life with our digital intelligence.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{paddingBottom:'0'}}>
        <div className="container">
          <div className="title-block text-center mb-4">
            <h2>Mobile Application Development Services</h2>
            <p>
            At RiseProSoft Solutions, we have a team of Mobile App Developers with extensive experience. They can transform your business ideas into reality by creating customer-centric mobile applications.
            </p>
          </div>
          <div className="work-grid-wrapper">
            <div className="row">
              {services.map((service) => (
                <div className="col-md-4 mb-4" key={service.id}>
                  <div className="dev-card">
                    <div className="icon">
                      <img src={service.imgSrc} alt={service.altText} />
                    </div>
                    <div className="card-body">
                      <h4 className="card-title">
                        <a href={service.link} title={service.title}>
                          {service.title}
                        </a>
                      </h4>
                      <p className="card-text">{service.description}</p>
                      <p className="text-end text-sm more-content">More <i className="fa fa-arrow-right" style={{color:'#1329e9'}}></i></p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="risepro-technology-stack-section">
  <div className="container">
    <h2 className="text-center mb-3">App Development Technology Stack</h2>
    <TechnologyStackGrid data={AppTechnologyStackData} />

  </div>
</section>
<Hireteam title={'Mobile App Developers'} />

<Contacttag />
<section className="risepro-faq-section pb-0">
  <div className="container">
    <h2 className="mb-5 text-center">Web Application Development FAQs</h2>
    <Accordion items={AppDevelopmentData} />
  </div>
</section>
      <Footer/>
    </React.Fragment>
  );
};
