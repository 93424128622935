import { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { scroller } from "react-scroll";

export const Header = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      scroller.scrollTo(location.hash.substring(1), {
        duration: 100,
        offset: -20,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    }
  }, [location]);
  const services = [
    {
      id: 1,
      title: 'Custom Software Development',
      link: '/custom-development',
    },
    {
      id: 2,
      title: 'Web Development',
      link: '/web-development',
    },
    {
      id: 3,
      title: 'Mobile App Development',
      link: '/app-development',
    },
    {
      id: 4,
      title: 'Product Development',
      link: '/product-development',
    },
    {
      id: 5,
      title: 'Dedicated Team',
      link: '/dedicated-development',
    },
    {
      id: 6,
      title: 'SAP Services',
      link: '/sap-development',
    },
    {
      id: 7,
      title: 'Testing & QA',
      link: '/testing',
    },
  ];
  return (
    <section className="head-section">
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <div className="container">
           
            <NavLink className="navbar-brand" to="/">
              <img src="images/logo.png" alt="" style={{ width: "160px" }} />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2">
                <li className="nav-item">
                  <NavLink className="nav-link" to="/">
                  <i class="fa fa-home"></i>
                  </NavLink>
                </li>
                <li className="nav-item dropdown">
                  <NavLink className="nav-link dropdown-toggle text-black" style={{color:'rgba(0, 0, 0, 0.7)'}} to="" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Services
                  </NavLink>
                  <ul className="dropdown-menu">
                   {services.map((service)=> ( <li key={service.id}><NavLink className="dropdown-item" to={service.link}>{service.title}</NavLink></li>))}
                   
                  </ul>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/technologies">
                    Technologies
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/" style={{color:'rgba(0, 0, 0, 0.7)'}}>
                    Industries
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/" style={{color:'rgba(0, 0, 0, 0.7)'}}>
                    Portfolio
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/about">
                    About
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/contactus">
                    Contact
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </section>
  );
};
