import React from "react";




// import { Header } from "./header";
// import { Footer } from "./home/footer";

export const Technologies = () => {
  return (
    <React.Fragment>
      {/* <Header /> */}
      <section className="about-section">
        <div className="container">
          <div className="banner-content-wrapper">
            <div className="container">
              <div className="banner-content text-center">
                <h1 className="text-white">Technologies</h1>
                <p className="text-white">
                Advancing digital disruptions with breakthrough software development solutions
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="technology-stack-section">
  <div className="container">
    {/* <h2>Technology Stack</h2> */}
   
  </div>
</section>



      {/* <Footer/> */}
    </React.Fragment>
  );
};
